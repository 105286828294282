import {
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from "@mui/material";
import { useLocation } from "react-router-dom";

export const SideNavItem = (props: any) => {
  const { disabled, icon, path, title, children } = props;
  const location = useLocation();
  const isSelected =
    path && !children
      ? location.pathname === "/" + path
      : location.pathname === "/";
  return (
    <ListItemButton
      sx={{
        alignItems: "center",
        borderRadius: 1,
        display: "flex",
        justifyContent: "flex-start",
        pl: "16px",
        pr: "16px",
        py: "6px",
        textAlign: "left",
        width: "100%",
        ...(isSelected && {
          backgroundColor: "rgba(255, 255, 255, 0.04)",
        }),
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.04)",
        },
      }}
      LinkComponent={Link}
      href={`/${path ? path : "/"}`}
    >
      {icon && (
        <ListItemIcon
          sx={{
            alignItems: "center",
            color: "neutral.400",
            display: "inline-flex",
            justifyContent: "center",
            mr: 2,
            ...(isSelected && {
              color: "primary.main",
            }),
          }}
        >
          <SvgIcon fontSize="small" sx={{ color: "neutral.500" }}>
            {icon}
          </SvgIcon>
        </ListItemIcon>
      )}
      <ListItemText
        sx={{
          color: "neutral.400",
          flexGrow: 1,
          fontFamily: (theme) => theme.typography.fontFamily,
          fontSize: 14,
          fontWeight: 600,
          lineHeight: "24px",
          whiteSpace: "nowrap",
          ...(isSelected && {
            color: "common.white",
          }),
          ...(disabled && {
            color: "neutral.500",
          }),
        }}
      >
        {title}
      </ListItemText>
    </ListItemButton>
  );
};
