import { Box, Button, Typography } from "@mui/material";
interface FetchErrorProps {
  repeatRequest: () => void;
}
export const FetchError = ({ repeatRequest }: FetchErrorProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h6" color="error">
        Erro ao carregar os dados
      </Typography>
      <Button onClick={repeatRequest} sx={{ marginTop: 2 }}>
        Tentar Novamente
      </Button>
    </Box>
  );
};
