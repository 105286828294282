import { Box, Tab, Tabs } from "@mui/material";
import { CustomTabPanel } from "../../../../components";
import { SyntheticEvent, useState } from "react";
import { AccountGeneralForm } from "./AccountGeneralForm";
import { AccountSecurityForm } from "./AccountSecurityForm";
import { AccountCircle, Lock } from "@mui/icons-material";
import { observer } from "mobx-react";
import { UserStore } from "../../../../stores";
import { Models } from "models";
import { toast } from "react-toastify";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const AccountForm = observer(() => {
  const [value, setValue] = useState(0);
  const { user, changePassword, update } = UserStore();

  const onChangePassword = async (userData: Models.IUserToUpdatePassword) => {
    try {
      await changePassword(userData);
      toast.success("Senha alterada com sucesso");
    } catch {
      toast.error("Erro na tentativa de alterar a senha");
    }
  };

  const onUpdateUser = async (userData: Partial<Models.IUserToUpdate>) => {
    try {
      await update(userData);
      toast.success("Dados alterados com sucesso");
    } catch {
      toast.error("Erro na tentativa de alterar os dados");
    }
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label="Geral"
            {...a11yProps(0)}
            icon={<AccountCircle />}
            iconPosition="start"
          />
          <Tab
            label="Segurança"
            {...a11yProps(1)}
            icon={<Lock />}
            iconPosition="start"
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <AccountGeneralForm user={user!!} onSubmit={onUpdateUser} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <AccountSecurityForm onSubmit={onChangePassword} />
      </CustomTabPanel>
    </Box>
  );
});
