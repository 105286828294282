import React from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { LeadEventForm } from "../components/forms/LeadEventForm";
import { leadEventsServices } from "../../../services/leadEventsServices";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Models } from "models";

export const LeadEventAddPage = () => {
  const [loading, setLoading] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const onSubmit = async (leadData: Partial<Models.ILeadEvents>) => {
    setLoading(true);
    try {
      await leadEventsServices.create(Number(id), leadData);
      toast.success("Evento cadastrado com sucesso");
      navigate(-1);
    } catch (error) {
      toast.error("Erro ao cadastrar evento");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Typography variant="h4">Cadastrar evento</Typography>
          <LeadEventForm onSubmit={onSubmit} isSubmitting={loading} />
        </Stack>
      </Container>
    </Box>
  );
};
