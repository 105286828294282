import React from "react";
import { LeadForm } from "./components/forms/LeadForm";
import { Box, Container, Stack, Typography } from "@mui/material";
import { leadServices } from "../../services/leadServices";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Models } from "models";

export const LeadAddPage = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const onSubmit = async (leadData: Partial<Models.ILead>) => {
    setLoading(true);
    try {
      await leadServices.create(leadData);
      toast.success("Lead cadastrado com sucesso");
      navigate(-1);
    } catch (error) {
      toast.error("Erro ao cadastrar lead");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Typography variant="h4">Cadastrar Lead</Typography>
          <LeadForm onSubmit={onSubmit} isSubmitting={loading} />
        </Stack>
      </Container>
    </Box>
  );
};
