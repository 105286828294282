import { useCallback } from "react";
import {
  Box,
  Divider,
  ListItemButton,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import { UserStore } from "../stores";
import { useNavigate } from "react-router-dom";

export const AccountPopover = observer((props: any) => {
  const { anchorEl, onClose, open } = props;
  const navigate = useNavigate();
  const { logout } = UserStore();

  const handleSignOut = useCallback(() => {
    logout();
  }, [logout]);

  const navigateToAccount = () => {
    navigate("/painel/perfil");
  };
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <ListItemButton onClick={navigateToAccount}>
          <Typography variant="overline">Minha conta</Typography>
        </ListItemButton>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        <MenuItem onClick={handleSignOut}>Sair</MenuItem>
      </MenuList>
    </Popover>
  );
});
