import { makeAutoObservable, runInAction } from "mobx";
import { sessionServices } from "../services/sessionServices";
import * as Network from "../services/api";
import { Models } from "models";
import { toJS } from "mobx";
import { userServices } from "../services";
class UserStoreMobx {
  _isSigned: boolean = false;
  signInLoading: boolean = false;
  signInError: boolean = false;
  _user: Partial<Models.IUser> | null = null;
  constructor() {
    makeAutoObservable(this);
    this.reset();
  }

  get isSigned() {
    return this._isSigned;
  }

  get isAdmin() {
    return this._user?.roleId === 1;
  }
  get user() {
    return toJS(this._user);
  }

  reset = () => {
    const accessToken = sessionStorage.getItem("accessToken");
    const refreshToken = sessionStorage.getItem("refreshToken");
    const user = sessionStorage.getItem("user");
    if (accessToken && refreshToken) {
      this._isSigned = true;
      this._user = JSON.parse(user!);
      Network.setAuthToken(accessToken);
    }
  };

  signIn = async ({ email, password }: { email: string; password: string }) => {
    try {
      runInAction(() => {
        this.signInLoading = true;
        this.signInError = false;
      });
      const { accessToken, refreshToken, user } = await sessionServices.auth({
        email,
        password,
      });

      runInAction(() => {
        this._isSigned = true;
        this.signInLoading = false;
        this._user = user;
      });
      Network.setAuthToken(accessToken);
      return { accessToken, refreshToken, user };
    } catch (error) {
      runInAction(() => {
        this.signInError = true;
        this.signInLoading = false;
      });
      throw error;
    }
  };

  update = async ({ name }: Partial<Models.IUserToUpdate>) => {
    try {
      const userResponse = await userServices.update({
        name,
      });
      runInAction(() => {
        this._user = userResponse;
      });
    } catch (error) {
      throw error;
    }
  };

  changePassword = async (
    userDataToUpdatePassword: Models.IUserToUpdatePassword
  ) => {
    try {
      const response = await userServices.changePassword({
        id: this._user?.id as number,
        userDataToUpdatePassword,
      });
      runInAction(() => {
        this._user = response;
      });
    } catch (error) {
      throw error;
    }
  };

  logout = () => {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("user");
    this._isSigned = false;
  };
}

let singleton: UserStoreMobx | null = null;

export const UserStore = (): UserStoreMobx => {
  if (!singleton) {
    singleton = new UserStoreMobx();
  }
  return singleton;
};
