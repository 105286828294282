import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton } from "@mui/material";

interface PaginationProps {
  page: number;
  fetchNextPage: () => void;
  fetchPrevPage: () => void;
  hasMore: boolean;
}

export const Pagination = ({
  page,
  fetchNextPage,
  fetchPrevPage,
  hasMore,
}: PaginationProps) => {
  return (
    <>
      <IconButton disabled={page === 1} onClick={fetchPrevPage}>
        <ChevronLeft />
      </IconButton>

      <IconButton disabled={!hasMore} onClick={fetchNextPage}>
        <ChevronRight />
      </IconButton>
    </>
  );
};
