import * as React from "react";
import {
  Button,
  TextField,
  Box,
  Grid,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Models } from "models";
import { InputPassword } from "../../../components";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

interface UserFormProps {
  user?: Models.IUser;
  roles: any[];
  onSubmit: (data: Models.IUserToCreate) => void;
  isSubmitting?: boolean;
}
const userSchema = z
  .object({
    name: z
      .string()
      .min(5, { message: "O nome escolhido é muito curto" })
      .transform((name) => {
        return name
          .trim()
          .split(" ")
          .map((word) => {
            return word[0]
              .toLocaleUpperCase()
              .concat(word.substring(1).toLowerCase());
          })
          .join(" ");
      }),
    email: z.string().email("E-mail inválido").toLowerCase(),
    password: z
      .string()
      .min(6, { message: "A senha deve ter pelo menos 6 caracteres" })
      .regex(/^(?=.*[!@#$%^&*])/, {
        message: "A senha deve conter pelo menos um caracter especial",
      }),
    confirmPassword: z.string(),
    roleId: z.number().default(2),
  })
  .refine((fields) => fields.password === fields.confirmPassword, {
    path: ["confirmPassword"],
    message: "As senhas não coincidem",
  });
type UserSchema = z.infer<typeof userSchema>;
export const UserForm = ({
  isSubmitting,
  user,
  roles,
  onSubmit,
}: UserFormProps) => {
  const [showPassword, setShowPassword] = React.useState<{
    password: boolean;
    confirmPassword: boolean;
  }>({
    password: false,
    confirmPassword: false,
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<UserSchema>({
    resolver: zodResolver(userSchema),
  });

  const onChangePasswordVisibility = (
    key: keyof { password: boolean; confirmPassword: boolean },
    value: boolean
  ) => {
    setShowPassword((prev) => ({ ...prev, [key]: value }));
  };

  const onSubmitTest = (formData: UserSchema) => {
    onSubmit({
      name: formData.name!,
      email: formData.email!,
      password: formData.password!,
      roleId: formData.roleId!,
    });
  };
  return (
    <Box sx={{ mt: 1 }}>
      <form onSubmit={handleSubmit(onSubmitTest)}>
        <Grid container spacing={3} xs={12} sm={12} lg={12}>
          <Grid item xs={12} sm={6}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="name"
                  label="Nome"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.name)}
                />
              )}
            />
            <FormHelperText error>{errors.name?.message}</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  id="email"
                  label="E-mail"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.email)}
                />
              )}
            />
            <FormHelperText error>{errors.email?.message}</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputPassword
                  {...field}
                  showPassword={showPassword.password}
                  label="Senha"
                  onChangePasswordVisibility={() =>
                    onChangePasswordVisibility(
                      "password",
                      !showPassword.password
                    )
                  }
                  outlinedInputProps={{
                    error: Boolean(errors.password),
                  }}
                />
              )}
            />

            <FormHelperText error>{errors.password?.message}</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputPassword
                  {...field}
                  showPassword={showPassword.confirmPassword}
                  label="Confirmar senha"
                  onChangePasswordVisibility={() =>
                    onChangePasswordVisibility(
                      "confirmPassword",
                      !showPassword.confirmPassword
                    )
                  }
                  outlinedInputProps={{
                    error: Boolean(errors.confirmPassword),
                  }}
                />
              )}
            />
            <FormHelperText error>
              {errors.confirmPassword?.message}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="roleId"
              control={control}
              defaultValue={2}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="roleId"
                  label="Perfil"
                  variant="outlined"
                  fullWidth
                  error={Boolean(errors.roleId)}
                  select
                >
                  {roles.map((role) => {
                    return (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    );
                  })}
                </TextField>
              )}
            />

            <FormHelperText error>{errors.roleId?.message}</FormHelperText>
          </Grid>
        </Grid>
        <Grid container spacing={3} xs={12} sm={6} lg={6}>
          <Grid item xs={12} sm={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isSubmitting}
              sx={{ mt: 3, mb: 2 }}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
