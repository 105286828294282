import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react";
import { UserStore } from "../../stores";
import { toast } from "react-toastify";
import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import { FormHelperText, Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { InputPassword } from "../../components";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const useStyles = makeStyles(() => ({
  container: {
    height: "100vh",
  },
  paper: {
    padding: "20px",
    borderRadius: "12px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "400px",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "200px",
  },
}));

const signInSchema = z.object({
  email: z.string().email("Preencha o email corretamente").toLowerCase(),
  password: z.string().min(1, { message: "A senha é obrigatória" }),
});
type SignInSchema = z.infer<typeof signInSchema>;

export const SignInPage = observer(() => {
  const { signIn, signInLoading } = UserStore();
  const classes = useStyles();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<SignInSchema>({
    resolver: zodResolver(signInSchema),
  });

  const submitHandler = async (formData: SignInSchema) => {
    try {
      const { accessToken, refreshToken, user } = await signIn({
        email: formData.email as string,
        password: formData.password as string,
      });
      sessionStorage.setItem("user", JSON.stringify(user));
      sessionStorage.setItem("accessToken", accessToken as string);
      sessionStorage.setItem("refreshToken", refreshToken as string);
      navigate("painel/dashboard");
    } catch (error: any) {
      toast.error("Usuário ou senha incorretos");
    }
  };

  return (
    <Container className={classes.container} component="main">
      <Paper className={classes.paper} elevation={3}>
        <Avatar sx={{ mt: 4, mb: 4, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Box>
          <form onSubmit={handleSubmit(submitHandler)}>
            <Grid container spacing={3} xs={12} sm={12} lg={12}>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="email"
                      label="E-mail"
                      variant="outlined"
                      fullWidth
                      error={Boolean(errors.email)}
                    />
                  )}
                />
                <FormHelperText error>{errors.email?.message}</FormHelperText>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputPassword
                      {...field}
                      showPassword={showPassword}
                      label="Senha"
                      onChangePasswordVisibility={() =>
                        setShowPassword(!showPassword)
                      }
                      outlinedInputProps={{
                        error: Boolean(errors.password),
                      }}
                    />
                  )}
                />

                <FormHelperText error>
                  {errors.password?.message}
                </FormHelperText>
              </Grid>
            </Grid>
            <Grid container spacing={3} xs={12} sm={12} lg={12}>
              <Grid item xs={12} sm={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={signInLoading}
                  sx={{ mt: 3, mb: 2 }}
                >
                  Entrar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Paper>
    </Container>
  );
});
