import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container, Stack } from "@mui/material";
import { AccountForm } from "./components/AccountForm";

export const EditUserPage = () => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Typography variant="h4">Minha Conta</Typography>
          <AccountForm />
        </Stack>
      </Container>
    </Box>
  );
};
