import * as React from "react";
import { Button, TextField, Box, Grid } from "@mui/material";
import { Models } from "models";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import {
  maskPhone,
  unmaskPhoneNumber,
  isValidPhoneNumber,
} from "../../../../utils/strings";
import { SelectUsersPaginated } from "../../../../components";
import { UserStore } from "../../../../stores";
interface LeadFormProps {
  lead?: Models.ILead;
  onSubmit: (data: Partial<Models.ILead>) => void;
  isSubmitting?: boolean;
}
export const LeadForm = ({ isSubmitting, lead, onSubmit }: LeadFormProps) => {
  const [formData, setFormData] = React.useState<Partial<Models.ILead>>({
    id: lead?.id,
    name: lead?.name || "",
    email: lead?.email || "",
    city: lead?.city || "",
    neighborhood: lead?.neighborhood || "",
    street: lead?.street || "",
    number: lead?.number || "",
    note: lead?.note || "",
    complement: lead?.complement || "",
    ddd: lead?.ddd || "",
    phone: `${lead?.ddd}${lead?.phone}` || "",
    latitude: lead?.latitude,
    longitude: lead?.longitude,
    energyConsumption: lead?.energyConsumption,
    date: lead?.date,
    userId: lead?.userId,
  });

  const { isAdmin } = UserStore();

  const onChangeFormValue = (
    key: keyof Models.ILeadCreateRequest | any,
    value: string
  ) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      ...(isValidPhoneNumber(formData.phone || "")
        ? {
            phone: formData.phone?.slice(2, 11),
            ddd: formData.phone?.slice(0, 2),
          }
        : { phone: "", ddd: "" }),
    });
  };

  return (
    <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
      <Grid container spacing={3} xs={12} sm={12} lg={12}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="name"
            name="name"
            label="Nome"
            fullWidth
            variant="outlined"
            value={formData.name}
            onChange={(e) => onChangeFormValue("name", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="email"
            name="email"
            label="E-mail"
            fullWidth
            variant="outlined"
            value={formData.email}
            onChange={(e) => onChangeFormValue("email", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="city"
            name="city"
            label="Cidade"
            fullWidth
            variant="outlined"
            value={formData.city}
            onChange={(e) => onChangeFormValue("city", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="neighborhood"
            name="neighborhood"
            label="Bairro"
            fullWidth
            variant="outlined"
            value={formData.neighborhood}
            onChange={(e) => onChangeFormValue("neighborhood", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="street"
            name="street"
            label="Rua"
            fullWidth
            variant="outlined"
            value={formData.street}
            onChange={(e) => onChangeFormValue("street", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="number"
            name="number"
            label="Número"
            fullWidth
            variant="outlined"
            value={formData.number}
            onChange={(e) => onChangeFormValue("number", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            id="energyConsumption"
            name="energyConsumption"
            label="Consumo de Energia"
            fullWidth
            variant="outlined"
            value={formData.energyConsumption}
            onChange={(e) =>
              onChangeFormValue("energyConsumption", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="latitude"
            name="latitude"
            label="Latitude"
            fullWidth
            variant="outlined"
            value={formData.latitude}
            onChange={(e) => onChangeFormValue("latitude", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="longitude"
            name="longitude"
            label="Longitude"
            fullWidth
            variant="outlined"
            value={formData.longitude}
            onChange={(e) => onChangeFormValue("longitude", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="complement"
            name="complement"
            label="Complemento"
            fullWidth
            variant="outlined"
            value={formData.complement}
            onChange={(e) => onChangeFormValue("complement", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="phone"
            name="phone"
            label="Telefone"
            fullWidth
            variant="outlined"
            value={maskPhone(formData.phone || "")}
            type="tel"
            onChange={(e) => {
              const unMaskedPhone = unmaskPhoneNumber(e.target.value);
              if (unMaskedPhone.length > 11) return;
              onChangeFormValue("phone", unMaskedPhone);
            }}
          />
        </Grid>

        {isAdmin && (
          <Grid item xs={12} sm={6}>
            <SelectUsersPaginated
              textFieldProps={{
                id: "owner",
                name: "owner",
                label: "Responsável",
                fullWidth: true,
                variant: "outlined",
              }}
              onChange={(e) => onChangeFormValue("userId", e.target.value)}
              value={String(formData.userId)}
            />
          </Grid>
        )}
        <Grid item xs={6} sm={6}>
          <DatePicker
            onAccept={(value) => {
              onChangeFormValue("date", moment(value).format().toString());
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            id="note"
            name="note"
            label="Observações"
            fullWidth
            variant="outlined"
            rows={6}
            multiline
            value={formData.note}
            onChange={(e) => onChangeFormValue("note", e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} xs={12} sm={6} lg={6}>
        <Grid item xs={12} sm={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isSubmitting}
            sx={{ mt: 3, mb: 2 }}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
