import { Models } from "models";
import * as Network from "./api";

export const userServices = {
  getAllUsers: async ({
    page,
    pageSize,
  }: Models.PaginatedQuery): Promise<
    Models.PaginatedResponse<Models.IUser>
  > => {
    try {
      const { data } = await Network.request<
        Models.PaginatedResponse<Models.IUser>
      >({
        url: "/v1/users",
        method: "GET",
        params: {
          page,
          pageSize,
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  create: async (userData: Models.IUserToCreate): Promise<void> => {
    try {
      const { data } = await Network.request<void>({
        url: "/v1/users",
        method: "POST",
        data: userData,
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  update: async (
    userData: Partial<Models.IUserToUpdate>
  ): Promise<Partial<Models.IUser>> => {
    try {
      const { data } = await Network.request<Partial<Models.IUser>>({
        url: "/v1/users",
        method: "PATCH",
        data: {
          name: userData.name,
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  changePassword: async ({
    id,
    userDataToUpdatePassword,
  }: {
    id: number;
    userDataToUpdatePassword: Models.IUserToUpdatePassword;
  }): Promise<Partial<Models.IUser>> => {
    try {
      const { data } = await Network.request<Partial<Models.IUser>>({
        url: `/v1/users/change-password`,
        method: "PATCH",
        data: {
          oldPassword: userDataToUpdatePassword.oldPassword,
          password: userDataToUpdatePassword.password,
          confirmPassword: userDataToUpdatePassword.confirmPassword,
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
};
