import { Models } from "models";
import * as Network from "./api";

export const leadServices = {
  getAllLeads: async ({
    page,
    pageSize,
  }: Models.PaginatedQuery): Promise<
    Models.PaginatedResponse<Models.ILead>
  > => {
    try {
      const { data } = await Network.request<
        Models.PaginatedResponse<Models.ILead>
      >({
        url: "/v1/leads",
        method: "GET",
        params: {
          page,
          pageSize,
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  show: async ({ id }: { id: number }): Promise<Models.ILead> => {
    try {
      const { data } = await Network.request<Models.ILead>({
        url: `/v1/leads/${id}`,
        method: "GET",
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  create: async (leadData: Partial<Models.ILead>): Promise<void> => {
    try {
      const { data } = await Network.request<void>({
        url: "/v1/leads",
        method: "POST",
        data: leadData,
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  update: async ({
    id,
    leadData,
  }: {
    id: number;
    leadData: Partial<Models.ILead>;
  }): Promise<Models.ILead> => {
    try {
      const { data } = await Network.request<void>({
        url: `/v1/leads/${id}`,
        method: "PATCH",
        data: leadData,
      });
      return data as unknown as Models.ILead;
    } catch (error) {
      throw error;
    }
  },
};
