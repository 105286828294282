export const Reason = [
  "Apresentação de Proposta",
  "Orçamento Gratuito",
  "Análise de Viabilidade",
  "Economia de Custos",
  "Incentivos Fiscais",
  "Customização do Projeto",
  "Demonstração de Tecnologia",
  "Garantias e Manutenção",
  "Avaliação do Consumo",
  "Benefícios Ambientais",
  "Independência Energética",
  "Valorização do Imóvel",
  "Conscientização Ambiental",
  "Segurança Energética",
  "Consultoria Especializada",
  "Parceria de Longo Prazo",
  "Eficiência Energética",
  "Retorno Rápido",
  "Acesso a Financiamentos",
  "Outros, descrito no campo aberto",
];

export const getProfileFromRoleId = (id: number) => {
  switch (id) {
    case 1:
      return "Administrador";
    case 2:
      return "Vendedor";
    default:
      return "Não definido";
  }
};

export const roles = [
  {
    id: 1,
    name: "Administrador",
  },
  {
    id: 2,
    name: "Vendedor",
  },
];
