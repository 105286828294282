import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

const baseURL = process.env.REACT_APP_API_URL;
const apiKey = process.env.REACT_APP_API_KEY;

export const REQUEST_TIMEOUT = 5000;

interface ApiError {
  message: string;
  statusCode?: number;
}

const axiosInstance = axios.create({
  baseURL,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    "x-api-key": apiKey,
  },
  params: {},
  timeout: REQUEST_TIMEOUT,
});

export const setAuthToken = (token: string) => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export async function request<T>({
  method,
  url,
  headers,
  data,
  params,
}: AxiosRequestConfig): Promise<AxiosResponse<T>> {
  try {
    const response = await axiosInstance.request<T>({
      url,
      method,
      data,
      headers,
      params,
    });
    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const apiError: ApiError = {
        message: error.message,
        statusCode: error.response?.status,
      };

      if (apiError.statusCode === 401) {
        // await refreshToken();
        // return request<T>({ url, method, data }); // Retry the original request
      }
      throw apiError;
    }

    throw error;
  }
}
