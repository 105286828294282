import * as React from "react";
import { Button, TextField, Box, Grid, MenuItem } from "@mui/material";
import { Models } from "models";
import { DatePicker } from "@mui/x-date-pickers";
import { Reason } from "../../../../utils/constants";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import { FilesContainer } from "./components/FilesContainer";
import { Dropzone } from "./components/Dropzone";
import { SelectUsersPaginated } from "../../../../components";
import { UserStore } from "../../../../stores";

interface LeadEventFormProps {
  event?: Models.ILeadEvents;
  onSubmit: (event: Models.ILeadEventsCreateRequest) => Promise<void>;
  isSubmitting?: boolean;
}

export const LeadEventForm = ({
  event,
  onSubmit,
  isSubmitting,
}: LeadEventFormProps) => {
  const [formData, setFormData] = React.useState<
    Partial<Models.ILeadEventsCreateRequest>
  >({
    reason: event?.reason || "",
    date: event?.date,
    comments: event?.comments || "",
    userId: event?.userId,
  });
  const [files, setFiles] = useState<any[]>([]);
  const { isAdmin } = UserStore();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles((prev) => [...prev, ...acceptedFiles]);
    },
    maxSize: 1000000 * 8,
    onDropRejected: (fileRejections) => {
      const message = fileRejections
        .map(({ file, errors }) => {
          const message = errors.map((error) => error.message).join(", ");
          return `${file.name} - ${message}`;
        })
        .join("\n");
      alert(message);
    },
  });

  const onChangeFormValue = (
    key: keyof Partial<Models.ILeadEventsCreateRequest>,
    value: string
  ) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("reason", formData.reason as string);
    formDataToSend.append("date", formData.date as unknown as string);
    formDataToSend.append("comments", formData.comments as string);
    formDataToSend.append("userId", String(formData.userId));

    for (const file of files) {
      formDataToSend.append("file", file);
    }

    try {
      await onSubmit(
        formDataToSend as unknown as Models.ILeadEventsCreateRequest
      );
    } catch (error) {
      console.error("Error submitting the form: ", error);
    }
  };
  return (
    <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
      <Grid container spacing={3} xs={12} sm={12} lg={12}>
        <Grid item xs={6} sm={4}>
          <TextField
            id="reason"
            name="reason"
            label="Motivo"
            select
            fullWidth
            variant="outlined"
            value={formData.reason}
            onChange={(e) => onChangeFormValue("reason", e.target.value)}
          >
            {Reason.map((reason) => {
              return (
                <MenuItem key={reason} value={reason}>
                  {reason}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
        {isAdmin && (
          <Grid item xs={6} sm={4}>
            <SelectUsersPaginated
              textFieldProps={{
                id: "owner",
                name: "owner",
                label: "Responsável",
                fullWidth: true,
                variant: "outlined",
              }}
              onChange={(e) => {
                console.log(e.target.value);
                onChangeFormValue("userId", e.target.value);
              }}
              value={String(formData.userId)}
            />
          </Grid>
        )}
        <Grid item xs={6} sm={4}>
          <DatePicker
            onAccept={(value) => {
              onChangeFormValue("date", moment(value).format().toString());
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="comments"
            name="comments"
            label="Comentários"
            fullWidth
            variant="outlined"
            value={formData.comments}
            multiline
            rows={5}
            onChange={(e) => onChangeFormValue("comments", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Dropzone rootProps={getRootProps} inputProps={getInputProps} />
        </Grid>
        <Grid>
          <FilesContainer files={files} setFiles={setFiles} />
        </Grid>
      </Grid>
      <Grid container spacing={3} xs={12} sm={6} lg={6}>
        <Grid item xs={12} sm={6}>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isSubmitting}
            sx={{ mt: 3, mb: 2 }}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
