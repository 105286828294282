import { observer } from "mobx-react";
import { Navigate } from "react-router-dom";
import { UserStore } from "../stores";

export const AuthenticatedRoute = observer(
  ({ component: Component, ...rest }: { component: any }) => {
    const { isSigned } = UserStore();

    return isSigned ? <Component {...rest} /> : <Navigate to="/" replace />;
  }
);
