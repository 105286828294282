import { Box, Button, Grid, MenuItem, TextField } from "@mui/material";
import { Models } from "models";
import { useState } from "react";
import { roles } from "src/utils/constants";

interface AccountGeneralFormProps {
  user?: Partial<Models.IUser>;
  onSubmit: (userData: Partial<Models.IUserToUpdate>) => void;
}

export const AccountGeneralForm = ({
  user,
  onSubmit,
}: AccountGeneralFormProps) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<Partial<Models.IUser>>({
    name: user?.name || "",
    email: user?.email || "",
    roleId: user?.roleId,
  });

  const onChangeFormValue = (key: keyof Models.IUser, value: string) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    await onSubmit({
      name: formData.name,
    });
    setLoading(false);
  };
  return (
    <Box component="form" onSubmit={handleFormSubmit} sx={{ mt: 1 }}>
      <Grid container spacing={3} xs={12} sm={12} lg={12}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="name"
            name="name"
            label="Nome"
            fullWidth
            variant="outlined"
            value={formData.name}
            onChange={(e) => onChangeFormValue("name", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="email"
            name="email"
            label="E-mail"
            fullWidth
            variant="outlined"
            value={formData.email}
            disabled
            onChange={(e) => onChangeFormValue("email", e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="roleId"
            name="roleId"
            label="Perfil"
            fullWidth
            select
            variant="outlined"
            value={formData.roleId}
            disabled={true}
            onChange={(e) => onChangeFormValue("roleId", e.target.value)}
          >
            {roles.map((role) => {
              return (
                <MenuItem key={role.id} value={role.id}>
                  {role.name}
                </MenuItem>
              );
            })}
          </TextField>
        </Grid>
      </Grid>
      <Grid container spacing={3} xs={12} sm={12} lg={12}>
        <Grid item>
          <Button
            type="submit"
            variant="contained"
            disabled={loading}
            sx={{ mt: 3, mb: 2 }}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
