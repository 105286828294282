import { Models } from "models";
import * as Network from "./api";
interface IUserToken {
  accessToken: string;
  refreshToken: string;
  user: Partial<Models.IUser>;
}
const SessionServices = {
  auth: async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<IUserToken> => {
    try {
      const { data } = await Network.request<IUserToken>({
        url: "/v1/auth",
        method: "POST",
        data: {
          email,
          password,
        },
      });
      return data;
    } catch (err) {
      throw err;
    }
  },
};

export const sessionServices = SessionServices;
