import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { Pagination } from "../../../components";

import { Models } from "models";
import { getProfileFromRoleId } from "../../../utils/constants";
import moment from "moment";
interface LeadTableProps {
  users: Models.IUser[];
  page: number;
  hasMore: boolean;
  fetchNextPage: () => void;
  fetchPrevPage: () => void;
}
export const UsersTable = ({
  users,
  page,
  fetchNextPage,
  fetchPrevPage,
  hasMore,
}: LeadTableProps) => {
  return (
    <Paper>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Perfil</TableCell>
              <TableCell>Data de Cadastro</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.length > 0 &&
              users.map((row) => (
                <TableRow
                  key={row.id}
                  hover
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{getProfileFromRoleId(row.roleId)}</TableCell>
                  <TableCell>
                    {moment(row.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        page={page}
        hasMore={hasMore}
        fetchNextPage={fetchNextPage}
        fetchPrevPage={fetchPrevPage}
      />
    </Paper>
  );
};
