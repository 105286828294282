import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Models } from "models";
import { Pagination } from "../../../../components";
import { handleDateFromLead } from "../../../../utils/strings";
interface LeadsTableProps {
  leads: Models.ILead[];
  page: number;
  hasMore: boolean;
  fetchNextPage: () => void;
  fetchPrevPage: () => void;
}
export const LeadsTable = ({
  leads,
  page,
  fetchNextPage,
  fetchPrevPage,
  hasMore,
}: LeadsTableProps) => {
  const navigate = useNavigate();
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>Observações</TableCell>
              <TableCell>Entrada do Lead</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leads.length > 0 &&
              leads.map((row) => (
                <TableRow
                  key={row.id}
                  hover
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    userSelect: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/painel/leads/" + row.id)}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell>{row.email || "-"}</TableCell>
                  <TableCell>{`${row.ddd}${row.phone}`}</TableCell>
                  <TableCell>{row.note || "Nenhuma observaçao"}</TableCell>
                  <TableCell>{handleDateFromLead(row)}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        page={page}
        hasMore={hasMore}
        fetchNextPage={fetchNextPage}
        fetchPrevPage={fetchPrevPage}
      />
    </Paper>
  );
};
