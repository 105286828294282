import { useState } from "react";
import { UserForm } from "./components/UserForm";
import { Box, Container, Stack, Typography } from "@mui/material";
import { Models } from "models";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { roles } from "../../utils/constants";
import { userServices } from "../../services/userServices";

export const CreateUserPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const onSubmit = async (leadData: Models.IUserToCreate) => {
    setLoading(true);
    try {
      await userServices.create(leadData);
      toast.success("Usuário cadastrado com sucesso");
      navigate(-1);
    } catch (error) {
      toast.error("Erro ao cadastrar usuário");
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Typography variant="h4">Cadastrar usuário</Typography>
          <UserForm onSubmit={onSubmit} isSubmitting={loading} roles={roles} />
        </Stack>
      </Container>
    </Box>
  );
};
