import * as React from "react";
import { Stack, Button, SvgIcon } from "@mui/material";
import { Models } from "models";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { leadEventsServices } from "../../../../services";
import { LeadEventsTable } from "../tables/LeadEventsTable";
import { FetchError, FullPageLoading } from "../../../../components";

export const LeadEventsTab = () => {
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [events, setEvents] = React.useState<Models.ILeadEvents[]>([]);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [nextPage, setNextPage] = React.useState(true);
  const [error, setError] = React.useState(false);
  const navigate = useNavigate();

  const fetchLeadEvents = React.useCallback(
    async ({ page = 1 }: { page?: number }) => {
      try {
        setLoading(true);
        const { data, nextPage }: Models.PaginatedResponse<Models.ILeadEvents> =
          await leadEventsServices.list({
            id: Number(id),
            page,
            pageSize: 10,
          });
        setNextPage(nextPage);
        setCurrentPage(page);
        setEvents(data);
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
        console.log("fin");
      }
    },
    [id]
  );

  React.useEffect(() => {
    fetchLeadEvents({ page: currentPage });
  }, [currentPage, fetchLeadEvents]);

  if (loading) {
    return <FullPageLoading />;
  }
  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="flex-end" spacing={4}>
        <div>
          <Button
            startIcon={
              <SvgIcon fontSize="small">
                <AddIcon />
              </SvgIcon>
            }
            variant="contained"
            onClick={() => navigate(`/painel/leads/${id}/eventos/novo`)}
          >
            Novo evento
          </Button>
        </div>
      </Stack>

      {error ? (
        <FetchError
          repeatRequest={() => fetchLeadEvents({ page: currentPage })}
        />
      ) : (
        <LeadEventsTable
          events={events}
          page={currentPage}
          hasMore={nextPage}
          fetchNextPage={() => {
            fetchLeadEvents({ page: currentPage + 1 });
          }}
          fetchPrevPage={() => {
            fetchLeadEvents({ page: currentPage - 1 });
          }}
        />
      )}
    </Stack>
  );
};
