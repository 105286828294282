import { createBrowserRouter } from "react-router-dom";
import { DashboardPage } from "../pages/dashboard/DashboardPage";
import { UsersList } from "../pages/users/UsersList";
import { SignInPage } from "../pages/signin/SignInPage";
import { AuthenticatedRoute } from "./AuthenticatedRoute";
import { LeadListPage } from "../pages/lead/LeadListPage";
import { LeadAddPage } from "../pages/lead/LeadAddPage";
import { LeadDetailPage } from "../pages/lead/LeadDetailPage";
import { LeadEventAddPage } from "../pages/lead/event/LeadEventAddPage";
import { UserStore } from "../stores";
import { CreateUserPage } from "../pages/users/CreateUser";
import { EditUserPage } from "../pages/users/EditUser";
import { Layout } from "../layout/layout";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";

export const router = createBrowserRouter([
  {
    id: "1",
    path: "/",
    element: <SignInPage />,
    async action() {
      UserStore().reset();
    },
  },
  {
    id: "2",
    element: <Layout />,
    children: [
      {
        id: "3",
        path: "/painel/dashboard",
        element: <AuthenticatedRoute component={DashboardPage} />,
      },
      {
        id: "4",
        path: "/painel/leads",
        element: <AuthenticatedRoute component={LeadListPage} />,
      },
      {
        id: "5",
        path: "/painel/leads/novo",
        element: <AuthenticatedRoute component={LeadAddPage} />,
      },
      {
        id: "6",
        path: "/painel/leads/:id",
        element: <AuthenticatedRoute component={LeadDetailPage} />,
      },
      {
        id: "8",
        path: "/painel/leads/:id/eventos/novo",
        element: <AuthenticatedRoute component={LeadEventAddPage} />,
      },
      {
        id: "10",
        path: "/painel/usuarios",
        element: <AuthenticatedRoute component={UsersList} />,
      },
      {
        id: "11",
        path: "/painel/usuarios/novo",
        element: <AuthenticatedRoute component={CreateUserPage} />,
      },
      {
        id: "12",
        path: "/painel/perfil",
        element: <AuthenticatedRoute component={EditUserPage} />,
      },
    ],
  },
]);

export const routes = [
  {
    id: 3,
    name: "Dashboard",
    path: "painel/dashboard",
    showOnList: true,
    icon: <DashboardIcon />,
  },
  {
    id: 4,
    name: "Leads",
    path: "painel/leads",
    showOnList: true,
    icon: <GroupIcon />,
    children: [
      {
        id: 5,
        name: "Listar",
        path: "painel/leads",
        showOnList: false,
      },
      {
        id: 6,
        name: "Cadastrar",
        path: "painel/leads/novo",
        showOnList: false,
      },
      {
        id: 7,
        name: "Visualizar Lead",
        path: "painel/leads/:id",
        showOnList: false,
      },
      {
        id: 9,
        name: "Cadastrar histórico",
        path: "painel/leads/:id/historico/novo",
        showOnList: false,
      },
    ],
  },
  {
    id: 12,
    name: "Usuários",
    path: "painel/usuarios",
    showOnList: true,
    icon: <PersonIcon />,
    children: [
      {
        id: 5,
        name: "Cadastrar usuário",
        path: "painel/usuarios/novo",
        showOnList: false,
      },
    ],
  },
];
