import { Models } from "models";
import * as Network from "./api";

export const leadEventsServices = {
  list: async (
    params: Models.PaginatedQuery & { id: number }
  ): Promise<Models.PaginatedResponse<Models.ILeadEvents>> => {
    try {
      const { data } = await Network.request<
        Models.PaginatedResponse<Models.ILeadEvents>
      >({
        url: `/v1/leads/${params.id}/events`,
        method: "GET",
        params: {
          page: params.page,
          pageSize: params.pageSize,
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  create: async (
    id: number,
    leadData: Models.ILeadEventsCreateRequest
  ): Promise<void> => {
    try {
      const { data } = await Network.request<void>({
        url: `/v1/leads/${id}/events`,
        method: "POST",
        data: leadData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  listFiles: async ({
    id,
  }: {
    id: number;
  }): Promise<Models.ILeadEventFile[]> => {
    try {
      const { data } = await Network.request<Models.ILeadEventFile[]>({
        url: `/v1/events/${id}/files`,
        method: "GET",
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
};
