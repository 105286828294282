import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

interface FilesContainerProps {
  files: any[];
  setFiles: React.Dispatch<React.SetStateAction<any[]>>;
}
export const FilesContainer = ({ files, setFiles }: FilesContainerProps) => {
  return (
    <React.Fragment>
      {files.length > 0 && (
        <List sx={{ mt: 2 }}>
          {files.map((file, index) => (
            <React.Fragment key={index}>
              <ListItem
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ListItemText
                  primary={file.name}
                  secondary={`${Number(file.size / 1000000).toFixed(2)} Mb`}
                />
                <ListItemIcon
                  sx={{ marginLeft: 2 }}
                  onClick={() => {
                    setFiles((prev) => prev.filter((_, i) => i !== index));
                  }}
                >
                  <DeleteIcon />
                </ListItemIcon>
              </ListItem>
              {index < files.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      )}
    </React.Fragment>
  );
};
