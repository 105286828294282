import { Box, Button, FormHelperText, Grid } from "@mui/material";
import { InputPassword } from "../../../../components";
import { useState } from "react";
import { Models } from "models";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

const accountSecuritySchema = z
  .object({
    oldPassword: z
      .string()
      .min(6, { message: "A senha deve ter pelo menos 6 caracteres" }),
    password: z
      .string()
      .min(6, { message: "A senha deve ter pelo menos 6 caracteres" })
      .regex(/^(?=.*[!@#$%^&*])/, {
        message: "A senha deve conter pelo menos um caracter especial",
      }),
    confirmPassword: z.string(),
  })
  .refine((fields) => fields.password === fields.confirmPassword, {
    path: ["confirmPassword"],
    message: "As senhas não coincidem",
  });

type AccountSecuritySchema = z.infer<typeof accountSecuritySchema>;

interface IUserChangePasswordVisibility {
  oldPassword: boolean;
  password: boolean;
  confirmPassword: boolean;
}

interface AccountSecurityFormProps {
  onSubmit: (userData: Models.IUserToUpdatePassword) => Promise<void>;
}

export const AccountSecurityForm = ({ onSubmit }: AccountSecurityFormProps) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] =
    useState<IUserChangePasswordVisibility>({
      oldPassword: false,
      password: false,
      confirmPassword: false,
    });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AccountSecuritySchema>({
    resolver: zodResolver(accountSecuritySchema),
  });

  const onChangePasswordVisibility = (
    key: keyof IUserChangePasswordVisibility,
    value: boolean
  ) => {
    setShowPassword((prev) => ({ ...prev, [key]: value }));
  };

  const handleFormSubmit = (formData: AccountSecuritySchema) => {
    setSubmitting(true);
    onSubmit(formData).finally(() => {
      setSubmitting(false);
    });
  };

  return (
    <Box sx={{ mt: 1 }}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Grid container spacing={3} xs={12} sm={12} lg={12}>
          <Grid item xs={12} sm={12}>
            <Controller
              name="oldPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputPassword
                  {...field}
                  showPassword={showPassword.oldPassword}
                  label="Senha atual"
                  onChangePasswordVisibility={() =>
                    onChangePasswordVisibility(
                      "oldPassword",
                      !showPassword.oldPassword
                    )
                  }
                  outlinedInputProps={{
                    error: Boolean(errors.oldPassword),
                  }}
                />
              )}
            />
            <FormHelperText error>{errors.oldPassword?.message}</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputPassword
                  {...field}
                  showPassword={showPassword.password}
                  label="Nova senha"
                  onChangePasswordVisibility={() =>
                    onChangePasswordVisibility(
                      "password",
                      !showPassword.password
                    )
                  }
                  outlinedInputProps={{
                    error: Boolean(errors.password),
                  }}
                />
              )}
            />
            <FormHelperText error>{errors.password?.message}</FormHelperText>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputPassword
                  {...field}
                  showPassword={showPassword.confirmPassword}
                  label="Repetir nova senha"
                  onChangePasswordVisibility={() =>
                    onChangePasswordVisibility(
                      "confirmPassword",
                      !showPassword.confirmPassword
                    )
                  }
                  outlinedInputProps={{
                    error: Boolean(errors.confirmPassword),
                  }}
                />
              )}
            />
            <FormHelperText error>
              {errors.confirmPassword?.message}
            </FormHelperText>
          </Grid>
        </Grid>
        <Grid container spacing={3} xs={12} sm={12} lg={12}>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              disabled={isSubmitting}
              sx={{ mt: 3, mb: 2 }}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};
