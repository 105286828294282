import * as React from "react";
import { Backdrop, CircularProgress, Container, Box } from "@mui/material";
import { Tab, Tabs } from "@mui/material";
import { SyntheticEvent } from "react";
import { AccountCircle } from "@mui/icons-material";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import { CustomTabPanel } from "src/components/CustomTabPanel";
import { LeadEventsTab } from "./components/tabs/LeadEventsTab";
import { observer } from "mobx-react-lite";
import { LeadDetailTab } from "./components/tabs/LeadDetailTab";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const LeadDetailPage = observer(() => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="xl">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={false}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Dados do Lead"
              {...a11yProps(0)}
              icon={<AccountCircle />}
              iconPosition="start"
              sx={{ pl: 1, pr: 1 }}
            />
            <Tab
              label="Eventos do Lead"
              {...a11yProps(1)}
              icon={<NoteAltIcon />}
              iconPosition="start"
              sx={{ pl: 1, pr: 1 }}
            />
          </Tabs>

          <CustomTabPanel value={value} index={0}>
            <LeadDetailTab />
          </CustomTabPanel>

          <CustomTabPanel value={value} index={1}>
            <LeadEventsTab />
          </CustomTabPanel>
        </Box>
      </Container>
    </Box>
  );
});
