import { MenuItem, TextField } from "@mui/material";
import { Models } from "models";
import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { userServices } from "../services";
import { FetchError } from "./FetchError";

interface SelectUsersPaginatedProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  textFieldProps?: React.ComponentProps<typeof TextField>;
  value: string;
}
export const SelectUsersPaginated = ({
  onChange,
  textFieldProps,
  value,
}: SelectUsersPaginatedProps) => {
  const [users, setUsers] = useState<Models.IUser[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(true);
  const [error, setError] = useState(false);

  const fetchUsers = useCallback(async ({ page = 1 }: { page?: number }) => {
    try {
      const { data, nextPage }: Models.PaginatedResponse<Models.IUser> =
        await userServices.getAllUsers({
          page,
          pageSize: 10,
        });
      setNextPage(nextPage);
      setCurrentPage(page);
      setUsers(data);
    } catch (error) {
      setError(true);
      toast.error("Erro ao listar usuários");
    }
  }, []);

  useEffect(() => {
    fetchUsers({ page: currentPage });
  }, [fetchUsers, currentPage]);

  return (
    <TextField
      {...textFieldProps}
      value={value}
      onChange={onChange}
      select
      fullWidth
    >
      {error ? (
        <FetchError repeatRequest={() => fetchUsers({ page: currentPage })} />
      ) : (
        users.map((user) => {
          return (
            <MenuItem key={user.id} value={user.id}>
              {user.name}
            </MenuItem>
          );
        })
      )}
      {nextPage && (
        <MenuItem onClick={() => fetchUsers({ page: currentPage + 1 })}>
          Carregar mais
        </MenuItem>
      )}
    </TextField>
  );
};
