import { Box, CircularProgress } from "@mui/material";

export const FullPageLoading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      mt={10}
    >
      <CircularProgress />
    </Box>
  );
};
