import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { UsersTable } from "./components/UsersTable";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { Models } from "models";
import { userServices } from "../../services/userServices";
import { toast } from "react-toastify";
import { observer } from "mobx-react";
import { UserStore } from "../../stores";

export const UsersList = observer(() => {
  const [users, setUsers] = useState<Models.IUser[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(true);
  const navigate = useNavigate();
  const { isAdmin } = UserStore();

  const fetchUsers = useCallback(async ({ page = 1 }: { page?: number }) => {
    try {
      const { data, nextPage }: Models.PaginatedResponse<Models.IUser> =
        await userServices.getAllUsers({
          page,
          pageSize: 10,
        });
      setNextPage(nextPage);
      setCurrentPage(page);
      setUsers(data);
    } catch (error) {
      toast.error("Erro ao listar usuários");
    }
  }, []);

  useEffect(() => {
    fetchUsers({ page: currentPage });
  }, [fetchUsers, currentPage]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography variant="h4">Usuários</Typography>
            {isAdmin && (
              <div>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <AddIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  onClick={() => navigate("/painel/usuarios/novo")}
                >
                  Novo
                </Button>
              </div>
            )}
          </Stack>
          <UsersTable
            users={users}
            page={currentPage}
            hasMore={nextPage}
            fetchNextPage={() => {
              fetchUsers({ page: currentPage + 1 });
            }}
            fetchPrevPage={() => {
              fetchUsers({ page: currentPage - 1 });
            }}
          />
        </Stack>
      </Container>
    </Box>
  );
});
