import { useState } from "react";
import { Models } from "models";
import { FullPageLoading, Pagination } from "../../../../components";

import { IconButton, Paper, TableHead, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { handleDateFromLeadEvents } from "../../../../utils/strings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useAxios } from "../../../../hooks/useAxios";
import { leadEventsServices } from "../../../../services/leadEventsServices";

interface LeadEventsTableProps {
  events: Models.ILeadEvents[];
  page: number;
  hasMore: boolean;
  fetchNextPage: () => void;
  fetchPrevPage: () => void;
}

interface ExpandableTableRowProps {
  id: number;
  children: React.ReactNode;
}
const ExpandableTableRow = ({
  id,
  children,
  ...otherProps
}: ExpandableTableRowProps) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const { data, loading, error, fetchData } = useAxios<Models.ILeadEventFile[]>(
    leadEventsServices.listFiles
  );

  const handleClick = () => {
    setIsExpanded(!isExpanded);
    fetchData({ id });
  };

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          <IconButton onClick={handleClick}>
            {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" colSpan={1} />
          <TableCell colSpan={4}>
            {loading && <FullPageLoading />}
            {error && (
              <Typography variant="caption" color={"red"}>
                Erro ao carregar arquivos
              </Typography>
            )}
            {data && data.length > 0 && (
              <>
                {data.map((file) => (
                  <Typography key={file.id}>{file.name}</Typography>
                ))}
              </>
            )}
            {data && data.length === 0 && (
              <Typography variant="caption">
                Nenhum arquivo adicionado ao evento
              </Typography>
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
export const LeadEventsTable = ({
  events,
  page,
  fetchNextPage,
  fetchPrevPage,
  hasMore,
}: LeadEventsTableProps) => {
  return (
    <Paper>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left"></TableCell>
              <TableCell>Data de contato</TableCell>
              <TableCell align="left">Motivo</TableCell>
              <TableCell align="left">Comentário</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event) => (
              <ExpandableTableRow id={event.id} key={event.id}>
                <TableCell>{handleDateFromLeadEvents(event)}</TableCell>
                <TableCell>{event.reason || "-"}</TableCell>
                <TableCell component="th" scope="row" align="left">
                  {event.comments}
                </TableCell>
              </ExpandableTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        page={page}
        hasMore={hasMore}
        fetchNextPage={fetchNextPage}
        fetchPrevPage={fetchPrevPage}
      />
    </Paper>
  );
};
