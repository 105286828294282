import { useState, useCallback } from "react";

type UseAxiosProps<T> = {
  data: T | null;
  loading: boolean;
  error: Error | null;
  fetchData: (...args: any[]) => void;
};

export const useAxios = <T>(
  fetchFunction: (...args: any[]) => Promise<T>
): UseAxiosProps<T> => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(
    async (...args: any[]) => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetchFunction(...args);
        setData(response);
      } catch (err) {
        setError(err as Error);
      } finally {
        setLoading(false);
      }
    },
    [fetchFunction]
  );

  return { data, loading, error, fetchData };
};
