import moment from "moment";
import { Models } from "../models";

export const maskPhone = (value: string) => {
  const numericValue = value.replace(/\D/g, "");
  const formattedValue = numericValue.replace(
    /^(\d{2})(\d{5})(\d{4})$/,
    "($1) $2-$3"
  );

  return formattedValue;
};

export function unmaskPhoneNumber(maskedPhoneNumber: string) {
  const unmasked = maskedPhoneNumber.replace(/\D/g, "");
  return unmasked;
}
export function isValidPhoneNumber(phoneNumber: string) {
  const cleaned = phoneNumber.replace(/\D/g, "");
  return cleaned.length === 11;
}

export const handleDateFromLead = (lead: Models.ILead) => {
  if (lead.date) {
    return moment(lead.date).format("DD/MM/YYYY");
  }
  return moment(lead.createdAt).format("DD/MM/YYYY");
};

export const handleDateFromLeadEvents = (leadEvents: Models.ILeadEvents) => {
  if (leadEvents.date) {
    return moment(leadEvents.date).format("DD/MM/YYYY");
  }
  return moment(leadEvents.createdAt).format("DD/MM/YYYY");
};
