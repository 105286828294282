import { useEffect } from "react";
import { Stack } from "@mui/material";
import { Models } from "models";
import { useParams } from "react-router-dom";
import { useAxios } from "src/hooks/useAxios";
import { leadServices } from "../../../../services";
import { LeadForm } from "../forms/LeadForm";
import { FetchError, FullPageLoading } from "../../../../components";

export const LeadDetailTab = () => {
  const { id } = useParams();

  const { data, loading, error, fetchData } = useAxios<Models.ILead>(
    leadServices.show
  );

  useEffect(() => {
    fetchData({ id });
  }, [id, fetchData]);

  if (loading) {
    return <FullPageLoading />;
  }
  return (
    <Stack spacing={3}>
      {error ? (
        <FetchError repeatRequest={() => fetchData({ id })} />
      ) : (
        <LeadForm lead={data!} onSubmit={() => {}} isSubmitting={loading} />
      )}
    </Stack>
  );
};
