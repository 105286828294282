import { Box, Typography } from "@mui/material";
import { DropzoneInputProps, DropzoneRootProps } from "react-dropzone";

interface DropzoneProps {
  rootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T;
  inputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T;
}
export const Dropzone = ({ rootProps, inputProps }: DropzoneProps) => {
  return (
    <Box
      {...rootProps()}
      sx={{
        border: "1px dashed #3f51b5",
        borderRadius: 4,
        padding: 4,
        textAlign: "center",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: 150,
        position: "relative",
      }}
    >
      <input {...inputProps()} />
      <Typography variant="body1" component="div">
        Arraste e solte seus arquivos aqui, ou clique para selecioná-los
      </Typography>
    </Box>
  );
};
