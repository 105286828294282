import { format, parseISO } from "date-fns";
import moment from "moment";

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const formattedDate = format(date, "dd/MM/yyyy", {
    locale: require("date-fns/locale/pt-BR"),
  });

  return formattedDate;
};

export function convertDateFormat(inputDate: string): string {
  const parsedDate = parseISO(inputDate);
  const formattedDate = format(parsedDate, "dd/MM/yyyy HH:mm");
  return formattedDate;
}

export const formatInputDate = (inputDate: string): string => {
  const numericValue = inputDate.replace(/[^\d]/g, "");
  const truncatedValue = numericValue.slice(0, 8);
  const day = truncatedValue.slice(0, 2);
  const month = truncatedValue.slice(2, 4);
  const year = truncatedValue.slice(4, 8);

  const isValidMonth = /^\d{2}$/.test(month) && parseInt(month, 10) <= 12;
  const isValidYear = /^\d{4}$/.test(year);

  if (isValidMonth && isValidYear) {
    const maxDaysInMonth = new Date(
      parseInt(year, 10),
      parseInt(month, 10),
      0
    ).getDate();
    const clampedDay = Math.min(parseInt(day, 10), maxDaysInMonth);
    const formattedDate = `${String(clampedDay).padStart(
      2,
      "0"
    )}/${month}/${year}`;
    return formattedDate;
  }

  return inputDate;
};

export const convertSimpleStringDateToMysqlFormat = (dateString: string) => {
  const [day, month, year] = dateString.split("/").map(Number);
  const dateObject = new Date(year, month - 1, day);

  const mysqlFormattedDate = dateObject.toISOString().split("T")[0];

  return mysqlFormattedDate;
};

export const convertDateToUTCFormat = (dateString: string) => {
  console.log(moment.utc(dateString));
  return moment.utc(dateString).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
};
