import { useCallback, useEffect, useState } from "react";
import { leadServices } from "../../services/leadServices";
import { Models } from "models";
import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import { LeadsTable } from "./components/tables/LeadsTable";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SearchInput } from "../../components";

export const LeadListPage = () => {
  const [leads, setLeads] = useState<Models.ILead[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(true);
  const navigate = useNavigate();

  const fetchLeads = useCallback(async ({ page = 1 }: { page?: number }) => {
    try {
      const { data, nextPage }: Models.PaginatedResponse<Models.ILead> =
        await leadServices.getAllLeads({
          page,
          pageSize: 10,
        });
      setNextPage(nextPage);
      setCurrentPage(page);
      setLeads(data);
    } catch (error) {
      toast.error("Erro ao listar leads");
    }
  }, []);

  useEffect(() => {
    fetchLeads({ page: currentPage });
  }, [currentPage, fetchLeads]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="xl">
        <Stack spacing={3}>
          <Stack direction="row" justifyContent="space-between" spacing={4}>
            <Typography variant="h4">Leads</Typography>
            <div>
              <Button
                startIcon={
                  <SvgIcon fontSize="small">
                    <AddIcon />
                  </SvgIcon>
                }
                variant="contained"
                onClick={() => navigate("/painel/leads/novo")}
              >
                Novo
              </Button>
            </div>
          </Stack>
          <SearchInput />
          <LeadsTable
            leads={leads}
            page={currentPage}
            hasMore={nextPage}
            fetchNextPage={() => {
              fetchLeads({ page: currentPage + 1 });
            }}
            fetchPrevPage={() => {
              fetchLeads({ page: currentPage - 1 });
            }}
          />
        </Stack>
      </Container>
    </Box>
  );
};
